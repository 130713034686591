import './spike.css'

function SpikeBar () {
    return (
        <div className="spike-bar">
                <div className='spike-row'>
                    {[...Array(80)].map(
                        () => (
                            <div className='spike'/>
                        )
                    )}
                </div>
            </div>
    );
} export default SpikeBar