import { 
    useState, 
    useCallback, 
    useEffect} from "react";
import { useParams } from "react-router-dom";
import ReactAudioPlayer from 'react-audio-player';

import AudioPlayer from "../../audioplayer/AudioPlayer.js";
import "./chfviewmatic.css"
import Draggable from "react-draggable";

const _ = require('lodash');

function CHFViewMatic({toggle, directory, manifest, pageNumber}) {
    const [component, setComponent] = useState("")

    useEffect(() => {
        setComponent(fetch_media());
    }, [pageNumber]);

    function token_to_path(token) {
        return "/resources/chf/" + directory + "/media/" + token.replace("-", "/");
    }

    function fetch_media() {
        if (_.isEmpty(manifest)) {
            return (<div/>);
        }
        let currentPageMedia = manifest.filter(element => element.page.includes(pageNumber));
        if (currentPageMedia != undefined) {
            return display_media(currentPageMedia);
        } else {
            return (<div/>);
        }
    }

    function display_media(media_entry) {
        return(
            <Draggable handle=".top-bar" bounds=".chf-root" defaultClassName="viewmatic"> 
                <span>
                    <div className="top-bar">
                        <button class="x-button" onClick={toggle}>
                                <div>X</div>
                            </button>
                    </div>
                    {media_entry.map(
                        media => {
                            let type = media.type;
                            if (type == "audio") {
                                return (
                                    <AudioPlayer path={token_to_path(media.uri)} title={media.title} artist={media.artist}/>
                                )
                            }
                        }
                    )}
                </span>
            </Draggable>
        )
    }

    return (
        <div>
        {component}
        </div>
    )

} export default CHFViewMatic