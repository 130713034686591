import { Outlet } from "react-router-dom";
import SpikeBar from "../components/spikes/SpikeBar";
import NavBar from "../components/navbar/NavBar";

import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import {slide as Menu} from "react-burger-menu"
import { NavLink } from "react-router-dom";
import InteractiveBG from "../components/interactivebg/InteractiveBG";


function GlobalLayout() {

  let location = useLocation();
  let stylePath = {
    "/culturehell/": "chf"
  }
  
  const [mainStyle, setMainStyle] = useState("")

  useEffect(() => {
    console.log(location);
    if (stylePath[location.pathname] !== undefined) {
      console.log(stylePath[location.pathname]);
      setMainStyle(stylePath[location.pathname]);
    } else {
      setMainStyle("");
    }
  }, [location])

  return (
    <div className={"main " + mainStyle}>
      {/* <InteractiveBG/> */}
      <div className="hamburger"> 
          <Menu>
              <NavLink to="/">Home</NavLink>
              <NavLink to="/srb2kart/">SRB2Kart</NavLink>
              <NavLink to="/culturehell/">Culture Hell Forever</NavLink>
              <NavLink to="/publicworks/">Public Works</NavLink>
              <NavLink to="/about/">About</NavLink>
          </Menu>
      </div>
      <div className="primary-grid">
        <div className="header">
          <SiteTitle/>
        </div>
        <div className="nav">
          <NavBar/>
        </div>
        <div className="site-body">
          <Outlet/>
        </div>
        <div className="footer">
          <Footer/>
        </div>
      </div>
    </div>
  );
}

export default GlobalLayout;

function SiteTitle() {
    return (
        <div className='header'> 
            <div className='title-bar'>
                <p className='top-title'>SHRIKE DOT ZONE</p>
                <p className='subtitle'>"Fashionably WIP!"</p>
            </div>
            <SpikeBar/>
        </div>
    );
}
  
function Footer() {
    return (
        <p>
        Site by Rubiline+Wyrmwood (rubiline@shrike.club). © 2023
        </p>
    );
}