import _ from "lodash"
import { Link } from "react-router-dom"
import "./chftoc.css"
import Draggable from "react-draggable";


function CHFToC({toggle, toc}) {

    function maptoc() {
        if (_.isEmpty(toc)) return ""
        console.log(toc);
        return toc.map(
                    (entry) => (
                    <li>
                        <Link to={"../"+(entry.page+1)} replace relative="path" reloadDocument>{entry.title}</Link>
                    </li>
                    )
                )
    }

    return (
        <Draggable handle=".top-bar" defaultClassName="toc" bounds=".chf-root">
            <div>
                    <div class="top-bar">
                        <button class="x-button" onClick={toggle}>
                            <div>X</div>
                        </button>
                    </div>
                    <h1>Table of Contents:</h1> 
                    <ul>
                    {maptoc()}
                    </ul>
                </div>
        </Draggable>
    )

} export default CHFToC