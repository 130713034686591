import Markdown from 'react-markdown'
import { useEffect, useState } from 'react';
import frontmatterPath from '../md/chf/chf_frontmatter.md'
import CHFCurrent from '../components/chf/chf-current/CHFCurrent';


function CultureHell () {
    const [frontmatter, setFrontmatter] = useState("");

    useEffect(() => {
        fetch(frontmatterPath).then(res => res.text()).then(md => setFrontmatter(md))
        }
    );

    return (
        <div className='culture-hell-home'>
            <Markdown children={frontmatter}/>
            <CHFCurrent/>
            <div className='culture-hell-issues'>
                <h1>PAST ISSUES:</h1>
            </div>
        </div>
    );
} export default CultureHell;