import Markdown from 'react-markdown'
import { useEffect, useState } from 'react';
import aboutPath from '../md/about.md'


function About () {
    const [markdown, setMarkdown] = useState("");

    useEffect(() => {
        fetch(aboutPath).then(res => res.text()).then(md => setMarkdown(md))
        }
    );

    return (
    <Markdown children={markdown}/>
    );
} export default About;