import './publicwork.css'

function ShrikeGit () {
    return (
        <div className='work-panel'>
            <p>GitShrike</p>
            <p>GitShrike is a Forgejo Git hosting instance hosted on this server. Think of it as an alternative to Github or Gitlab. Please be responsible with your uploads. SSH authentication is not presently working.</p>
            <div className='url-panel'>
                <a href='https://git.shrike.zone'>git.shrike.zone</a>
            </div>
        </div>
    )
} export default ShrikeGit;