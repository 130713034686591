import React from 'react';

import play from "../../res/images/play.png"
import pause from "../../res/images/pause.png"
import loop from "../../res/images/loop.png"
import noloop from "../../res/images/noloop.png"

import "./audiocontrols.css"

function AudioControls({ isPlaying, onPlayPauseClick, isLooping, onLoopClick}) {
    return (
        <div className="audio-controls">
            {isPlaying ? (
                <button
                    type="button"
                    className="pause"
                    onClick={() => onPlayPauseClick(false)}
                    aria-label="Pause"
                    title="Pause"
                >
                    <img src={pause}/>
                </button>
                ) : (
                <button
                    type="button"
                    className="play"
                    onClick={() => onPlayPauseClick(true)}
                    aria-label="Play"
                    title="Play"
                >
                    <img src={play}/>
                </button>
                )
            }

            {isLooping ? (
                <button type="button" className="loop" onClick={() => onLoopClick(false)} title="Stop Looping" aria-label="Stop Looping"> <img src={noloop}/> </button>
            ) : (
                <button type="button" className="loop" onClick={() => onLoopClick(true)} title="Loop" aria-label="Loop"> <img src={loop}/> </button>
            )
        }
        </div>
    );
} export default AudioControls
