import './kartstatuspanel.css'
import { useEffect, useState } from 'react';
import srb2klogo from './res/kartlogo.png'

function KartStatusPanel() {
    return (
      <div className='kart-panel modalbox neu'>
        <img src={srb2klogo} width="80%"/>
        <br/>
        <KartPlayerCount/>
        <div className='ip-panel'>
            <p >IP: kart.shrike.zone</p>
        </div>
      </div>
    );
  } export default KartStatusPanel;
  


function KartPlayerCount() {
    const [maxPlayers, setMaxPlayers] = useState(0);
    const [numPlayers, setNumPlayers] = useState(0);
    const [currentMap, setCurrentMap] = useState("");
    const [api, setAPI] = useState(true);
    
    function QueryKartServer() {
        fetch('/api/kart')        
        .then(res => res.json())
        .then(data => {
            setMaxPlayers(data.maxPlayers);
            setNumPlayers(data.numPlayers);
            setCurrentMap(data.mapTitle);
            setAPI(true);
            }
        )
        .catch((error) => {
            if (error.response) {
                console.log("Malformed API response.");
                setAPI(false);
            } else {
                console.log("API not found.");
                setAPI(false);
            }
        });
    };

    useEffect(() => {
        QueryKartServer();
        /*
        const interval = setInterval(QueryKartServer, 10000);
        return() => clearInterval(interval);
        */
        }
    );
    if (api == false) {
        return (<p> Server is down. Try again later? </p>)
    }
    else if (numPlayers === 0) {
        return (<p> Server is empty! Why not join? </p>)
    } else {
        return (<p>
        Currently playing: {numPlayers} / {maxPlayers} on map {currentMap}.
        </p>)
    }
}
  
