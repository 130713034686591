import './css/root.css';
import GlobalLayout from './pages/Layout';
import Home from './pages/Home';
import About from './pages/About';
import SRB2Kart from './pages/SRB2Kart';
import PublicWorks from './pages/PublicWorks'

import { BrowserRouter, Routes, Route } from "react-router-dom";
import CultureHell from './pages/CultureHell';
import CultureHellPDF from './components/chf/chf-issue/CHFIssue';

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/culturehell/:zine_token/:page?' element={<CultureHellPDF/>}/>
        <Route path='/' element={<GlobalLayout/>}>
          <Route index element={<Home/>}/>
          <Route path="srb2kart/" element={<SRB2Kart/>}/>
          <Route path="about/" element={<About/>}/>
          <Route path="culturehell/" element={<CultureHell/>}/>
          <Route path="publicworks/" element={<PublicWorks/>}/>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
