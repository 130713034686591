import { useResizeObserver } from '@wojtekmaj/react-hooks';
import { pdfjs, Document, Page } from 'react-pdf';
import { 
    useState, 
    useCallback } from "react";
    
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import "./style.scss"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const resizeObserverOptions = {};

function CHFPageSpreadMobile ({zineToken, pdf, pageNumber, onDocumentLoadSuccess}) {

    const [containerWidth, setContainerWidth] = useState(100);
    const [containerRef, setContainerRef] = useState(null);

    const onResize = useCallback((entries) => {
        const [entry] = entries;
    
        if (entry) {
          setContainerWidth(entry.contentRect.width);
        }
      }, []);

    useResizeObserver(containerRef, resizeObserverOptions, onResize);

    return (
        <div className='spread-container' ref={setContainerRef} >
            <div className="chf-container" style={{"--cw": containerWidth+'px'}}>
                <Document file={("https://shrikezone.b-cdn.net/chf/" + zineToken + "/" + pdf)} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page pageNumber={pageNumber-1} width={containerWidth}/>
                </Document> 
            </div>
        </div>
    )
} export default CHFPageSpreadMobile;