import {NavLink} from "react-router-dom";

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import './style.scss'
import CHFViewMatic from "../../chf-viewomatic/CHFViewMatic";
import CHFToC from "../../chf-toc/CHFToC";
import CHFPageSpreadMobile from "./CHFPageSpreadMobile";

import _ from "lodash";

function CHFLayoutMobile ({currentMedia, zine_token, onDocumentLoadSuccess, toggle_toc, tocVis, is_vm, toggle_vm, vmVis, pageNumber, numPages, iterate}) {

    return (
        <div className="chf-m">
            <div className="chf-m-warning">
                <p>Culture Hell Forever is best experienced on a monitor.</p>
            </div>
            {tocVis?<CHFToC toc={currentMedia.toc} toggle={toggle_toc}/>:<div/>}
            {vmVis ? 
                <CHFViewMatic toggle={toggle_vm} directory={zine_token} manifest={currentMedia.media} pageNumber={pageNumber}/>    
                :<div/>
            }    
            <div className="chf-display"> 
                <CHFPageSpreadMobile zineToken={zine_token} pdf={currentMedia.pdf} pageNumber={pageNumber} onDocumentLoadSuccess={onDocumentLoadSuccess}/>

                <div className="chf-navbar">
                    <div className="chf-navbuttons">
                        <button onClick={() => iterate(1)}>FIRST</button>
                        <button onClick={() => iterate(pageNumber-1)}>BACK</button>
                        <button onClick={() => iterate(pageNumber+1)}>NEXT</button> 
                        <button onClick={() => iterate(numPages)}>LAST</button>  
                    </div>
                    <p>{pageNumber} / {numPages} </p>
                </div>
            </div>
        </div>
    );

} export default CHFLayoutMobile