import {NavLink} from "react-router-dom";

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import './style.css'
import CHFViewMatic from "../../chf-viewomatic/CHFViewMatic";
import CHFToC from "../../chf-toc/CHFToC";
import CHFPageSpreadWeb from "./CHFPageSpreadWeb";

import _ from "lodash";

function CHFLayoutWeb ({currentMedia, zine_token, onDocumentLoadSuccess, toggle_toc, tocVis, is_vm, toggle_vm, vmVis, pageNumber, numPages, iterate}) {

    return (
        <div className="chf-w">
            {tocVis?<CHFToC toc={currentMedia.toc} toggle={toggle_toc}/>:<div/>}
            {vmVis ? 
                <CHFViewMatic toggle={toggle_vm} directory={zine_token} manifest={currentMedia.media} pageNumber={pageNumber}/>    
                :<div/>
            }    
            <div className="chf-display"> 
                <div className="chf-rootnav">
                    <NavLink to={"/culturehell/"} className="chf-navbutton chf-return"> Back to Culture Hell... </NavLink>
                    <button className="chf-navbutton chf-toc-button" onClick={toggle_toc} disabled={tocVis}>OPEN TABLE OF CONTENTS</button>
                    {is_vm() ? <button className="chf-navbutton chf-viewmatic-button" onClick={toggle_vm} disabled={vmVis}>MEDIA AVAILABLE</button> : <div/>}
                    <a  className="chf-dl chf-navbutton"
                        href={"https://shrikezone.b-cdn.net/chf/"+zine_token+"/"+currentMedia.fullres}
                        target="_blank"
                        rel="noopener noreferrer"
                    >Download full res...</a>
                </div>

                <CHFPageSpreadWeb zineToken={zine_token} pdf={currentMedia.pdf} pageNumber={pageNumber} onDocumentLoadSuccess={onDocumentLoadSuccess}/>

                <div className="chf-navbar">
                    <div className="chf-navbuttons">
                        <button className="chf-navbutton" onClick={() => iterate(1)}>FIRST</button>
                        <button className="chf-navbutton" onClick={() => iterate(pageNumber-2)}>BACK</button>
                        <button className="chf-navbutton" onClick={() => iterate(pageNumber+2)}>NEXT</button> 
                        <button className="chf-navbutton" onClick={() => iterate(numPages)}>LAST</button>  
                    </div>
                    <p>{pageNumber} / {numPages} </p>
                </div>
            </div>
        </div>
    );

} export default CHFLayoutWeb