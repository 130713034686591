import KartStatusPanel from "../components/kartstatus/KartStatusPanel";

function Home() {
    return (
        <KartStatusPanel/>
    )
}

export default Home;

