import Markdown from 'react-markdown'
import { useEffect, useState } from 'react';
import publicWorks from '../md/publicworks/publicworks.md'
import ShrikeGit from '../components/publicwork/ShrikeGit';

function PublicWorks () {
    const [markdown, setMarkdown] = useState("");

    useEffect(() => {
        fetch(publicWorks).then(res => res.text()).then(md => setMarkdown(md))
        }
    );

    return (
        <div>
            <Markdown children={markdown}/>
            <ShrikeGit/>
        </div>
    );
} export default PublicWorks;