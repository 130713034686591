import { 
  useState, 
  useEffect,
  useRef} from "react";

import AudioControls from "./AudioControls";
import "./audioplayer.css"



function AudioPlayer({path, title, artist}) {

    const [trackProgress, setTrackProgress] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [looping, setLooping] = useState(false);

    const audioRef = useRef(new Audio(path));
    const intervalRef = useRef();

    const { duration } = audioRef.current;

    useEffect(() => {
        if (isPlaying) {
          audioRef.current.play();
          startTimer();
        } else {
          audioRef.current.pause();
        }
      }, [isPlaying]);

    useEffect(() => {
      if (looping) {
        audioRef.current.loop = true;
      } else {
        audioRef.current.loop = false;
      }
    }, [looping])

    useEffect(() => {
        // Pause and clean up on unmount
        return () => {
          audioRef.current.pause();
          clearInterval(intervalRef.current);
        }
      }, []);

    const startTimer = () => {
        // Clear any timers already running
        clearInterval(intervalRef.current);
  
        intervalRef.current = setInterval(() => {
          if (audioRef.current.ended && !audioRef.current.loop) {
            setIsPlaying(false);
            setTrackProgress(0);
          } else {
            setTrackProgress(audioRef.current.currentTime);
          }
        }, [1000]);
    }

    const onScrub = (value) => {
        // Clear any timers already running
      clearInterval(intervalRef.current);
      audioRef.current.currentTime = value;
      setTrackProgress(audioRef.current.currentTime);
    }
    
    const onScrubEnd = () => {
      // If not already playing, start
      if (!isPlaying) {
        setIsPlaying(true);
      }
      startTimer();
    }
    

    return (
		<div className="audio-player">
      <div className="topbar"></div>
			<div className="track-info">
                <h2 className="title">{title}</h2>
                <h3 className="artist">{artist}</h3>
      <div className="track-controls">
        </div>
        <AudioControls
              isPlaying={isPlaying}
              onPlayPauseClick={setIsPlaying}
              isLooping={looping}
              onLoopClick={setLooping}
          />
        <input
                      type="range"
                      value={trackProgress}
                      step="1"
                      min="0"
                      max={duration ? duration : `${duration}`}
                      className="progress"
                      onChange={(e) => onScrub(e.target.value)}
                      onMouseUp={onScrubEnd}
                      onKeyUp={onScrubEnd}
                  />
      </div>
		</div>
    )

} export default AudioPlayer