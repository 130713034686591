import { useState, useEffect } from 'react';
import './chfcurrent.css'
import Markdown from 'react-markdown'
import { NavLink } from 'react-router-dom';

function CHFCurrent () {
    const [currentCHF, setCurrentCHF] = useState({
        title: "",
        directory: "",
        icon: "",
        blurb: ""
    });

    const [currentBlurb, setCurrentBlurb] = useState("");

    function fetch_chf () {
        fetch('/api/chf/featured').
        then(data => data.json()).
        then(json => {
            setCurrentCHF(json);
        });
    }

    function fetch_blurb () {
        fetch(currentCHF.blurb).then(blurb => blurb.text()).then(bl => setCurrentBlurb(bl));
    }

    useEffect(() => {
            fetch_chf();
        }, []
    );

    useEffect(() => {
            fetch_blurb();
        }, [currentCHF.blurb]
    );

    return (
        <div className="chf-current">
            <div className='chf-header'>
                <p className='current-header'>LATEST ISSUE</p>
            </div>
            <div className='blurb-box'>
                <img src={currentCHF.icon}/>
                <div classNaME='blurb-text'>
                    <Markdown children={currentBlurb}/>
                </div>
            </div>
            <div className='read-box'>
                <NavLink to={'/culturehell/' + currentCHF.directory} className='read-below'>Read online here</NavLink>
            </div>
        </div>
    )
} export default CHFCurrent

