import { Link, NavLink, useParams, useSearchParams } from "react-router-dom";
import { 
    useState, 
    useCallback, useEffect } from "react";
    
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import './chfissue.css'
import { generatePath, useNavigate, matchRoutes } from 'react-router';

import {slide as Menu } from 'react-burger-menu'
import CHFLayoutWeb from "./web/CHFLayoutWeb";
import CHFLayoutMobile from "./mobile/CHFLayoutMobile";

import _ from "lodash";

function CultureHellPDF () {
    let {zine_token, page} = useParams();
    const navigate = useNavigate();

    const [pageNumber, setPageNumber] = useState(0);
    const [numPages, setNumPages] = useState(1);
    const [tocVis, setTocVis] = useState(false);
    const [vmVis, setVmVis] = useState(false);

    const [currentMedia, setCurrentMedia] = useState({
        pdf: "",
        fullres: "",
        media: [],
        toc: []
    });

    const [isMobile, setIsMobile] = useState(false)
    
    // create an event listener
    useEffect(() => {
      window.addEventListener("resize", handleResize)
    })

    useEffect(() => {
        handleResize();
        fetch_media();
    }, [zine_token]
    );

    function fetch_media () {
        fetch('/api/zine-manifest/'+zine_token).
        then(data => data.json()).
        then(json => {
            setCurrentMedia(json);
        });
    }

    //choose the screen size 
    const handleResize = () => {
        if (window.innerWidth / window.innerHeight <= 0.80 || window.innerHeight < 800) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
            setPageNumber(current => current + (current % 2 - 1))
        }
    }

    const toggle_toc = () => {
        setTocVis(current => !current)
    }

    const toggle_vm = () => {
        setVmVis(current => !current)
    };

    function is_vm () {
        return !(_.isEmpty(currentMedia.media) || !currentMedia.media.some(element => element.page.includes(pageNumber)));
    };
  
    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        setNumPages(nextNumPages);

        if (page != undefined) {
            console.log(page);
            setPageNumber(Number(page));

        } else {
            const savedPage = JSON.parse(localStorage.getItem('page-' + zine_token));
            console.log("Loaded page " + savedPage + " at zine " + zine_token);
            if (savedPage != null) {
                navigate(""+savedPage, {replace : true})
                setPageNumber(Number(savedPage))
            } else {
                console.log("Setting initial page!");
                navigate(""+1, {replace : true});
                setPageNumber(1);
            }
        }
    }

    function iterate(to_page) {
        if (pageNumber == 0) return;
        to_page = Math.max( 1, Math.min(to_page, numPages));
        setPageNumber(to_page);
        navigate("../"+to_page, {relative: "path", replace : true});
        localStorage.setItem('page-' + zine_token, JSON.stringify(to_page));
    }

    return (
        <div id="chf">
            <div className="chf-root" id="page-wrap">
                <div className="bg"/>
                {isMobile ? 
                <CHFLayoutMobile                    
                    currentMedia={currentMedia} 
                    zine_token={zine_token} 
                    onDocumentLoadSuccess={onDocumentLoadSuccess} 
                    toggle_toc={toggle_toc} 
                    tocVis={tocVis}
                    toggle_vm={toggle_vm}
                    vmVis={vmVis}
                    pageNumber={pageNumber}
                    numPages={numPages}
                    iterate={iterate}/> 
                : 
                <CHFLayoutWeb 
                    currentMedia={currentMedia} 
                    zine_token={zine_token} 
                    onDocumentLoadSuccess={onDocumentLoadSuccess} 
                    toggle_toc={toggle_toc} 
                    tocVis={tocVis}
                    is_vm={is_vm}
                    toggle_vm={toggle_vm}
                    vmVis={vmVis}
                    pageNumber={pageNumber}
                    numPages={numPages}
                    iterate={iterate}/>
                }
            </div>
        </div>
    );

} export default CultureHellPDF